import React from 'react';
import { Box, Skeleton, Typography, Chip } from '@mui/material';
import { AssetTypeSummaryExternal } from '@askporter/client-grieg-lyric';
import { generateAssetTitle, getInitialsFromLongName } from '@askporter/utils';
import { Avatar, AvatarSize } from '../Avatar';
import Icon, { IconSize } from '../Icon';

export interface AssetAvatarProps {
  /**
   * title that goes beside Avatar
   */
  title: string;
  /**
   * asset ref that goes beside Avatar
   */
  assetRef: string;
  /**
   * asset postcode that goes beside Avatar
   */
  postalCode?: string;
  /**
   * avatar mode
   */
  mode?: 'picture-only' | 'full';
  /**
   *  type of asset
   */
  assetType: AssetTypeSummaryExternal;
  /**
   * optional - imagePath for avatar
   */
  imagePath?: string;
  /**
   * optional - the data-testid string for the component
   */
  dataTestId?: string;
  /**
   * optional - boolean to indicate if the avatar is loading
   */
  isLoading?: boolean;
  /**
   * optional - whether is a filter option or not
   */
  isFilterOption?: boolean;
  /**
   * optional - whether is small device or not
   */
  isSmallDevice?: boolean;
  /**
   * optional - asset uid
   */
  uid?: string;
  /**
   * optional - custom avatar size
   */
  customAvatarSize?: AvatarSize;
}

/**
 * AssetAvatar that composes Avatar for Teams or Orgs
 */
export const AssetAvatar: React.FC<React.PropsWithChildren<AssetAvatarProps>> = ({
  title,
  dataTestId = 'asset-avatar',
  mode = 'full',
  assetRef,
  assetType,
  postalCode,
  imagePath,
  uid,
  isLoading,
  isFilterOption = false,
  customAvatarSize,
  ...assetAvatarProps
}: AssetAvatarProps): JSX.Element => {
  const avatarSize = customAvatarSize ? customAvatarSize : AvatarSize.XL;
  const iconSize = IconSize.MD;

  return (
    <Box display="flex">
      <Avatar
        dataTestId={dataTestId}
        altText={title}
        isLoading={isLoading}
        avatar={{
          imagePath: imagePath ? imagePath : assetType?.icon ? assetType.icon.iconPath : undefined,
          size: { container: avatarSize, icon: iconSize },
          bgcolor: assetType?.icon?.iconColourMask,
          mode: imagePath ? 'image' : assetType?.icon?.iconPath ? 'icon' : 'initials',
          variant: 'rounded',
          initials: getInitialsFromLongName(title),
        }}
        {...assetAvatarProps}
      />
      {mode === 'full' && (
        <Box sx={{ ml: 4, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isFilterOption && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                data-testid={`${uid}-asset-list-card-header-overline`}
              >
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width={avatarSize * 1.5} />
                ) : assetRef ? (
                  assetRef
                ) : (
                  ''
                )}
              </Typography>
            )}
          </Box>
          <Typography noWrap variant="h6" data-testid={`${uid}-asset-list-card-header-title`}>
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width={avatarSize * 2} />
            ) : postalCode ? (
              generateAssetTitle(title, postalCode)
            ) : (
              title
            )}
          </Typography>
          {!isFilterOption && isLoading ? (
            <Skeleton variant="rectangular" animation="wave" width={avatarSize * 2} />
          ) : (
            !isFilterOption && (
              <Chip
                data-testid={`${uid}-asset-list-card-header-chip`}
                label={assetType?.displayName}
                icon={assetType?.icon?.iconPath && <Icon fullPath={assetType?.icon?.iconPath} size={IconSize.SM} />}
                sx={{
                  p: 0,
                  bgcolor: 'transparent',
                }}
              />
            )
          )}
        </Box>
      )}
    </Box>
  );
};
