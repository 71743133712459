import React, { useRef } from 'react';
import { AppBar, Typography, Box, Skeleton, useTheme, useMediaQuery, Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { TenantConfig, UserReadExternal } from '@askporter/client-grieg-lyric';
import { CU_FeatureFlags } from '@askporter/utils';
import { Logo } from '../Logo';
import AccountMenu from './components/AccountMenu';
import FullNavBarLink from './components/FullNavBarLink';
import MobileAccountMenu from './components/MobileAccountMenu';
import ProfileAvatarButton from './components/ProfileAvatarButton';
import {
  createIconNavButtonLinks,
  useResponsiveProperties,
  iconNavButtons,
  fullNavButtons,
  fullNavButtonLinks,
  menuOnlyNavButtonLinks,
  menuOnlyNavButtons,
  checkIfNavButtonIsAllowed,
} from './utils';
import type { FullNavButton, IconNavButton, MenuOnlyNavButton } from './utils';

interface CommonProps {
  /**
   * Translation function
   */
  t: (key: string) => string;
  /**
   * Tenant configuration
   */
  config: TenantConfig;
}

export type NavigtaionProps = CommonProps &
  (
    | {
        /**
         * 'simple' for a navigation with a logo only, or 'complete' for a navigation with all the functionality
         * */
        mode?: 'complete';
        /**
         * Profile of the currently authenticated user
         */
        userProfile: Partial<UserReadExternal>;
        /**
         * Function to trigger when the user chooses to sign out
         */
        handleSignOut: () => void;
        /**
         * optional page title which is rendered if mode is set to "simple"
         * */
        simpleNavigationTitle?: never;
      }
    | {
        mode: 'simple';
        simpleNavigationTitle?: string;
        userProfile?: never;
        handleSignOut?: never;
      }
  );

/** Navigation (navbar) component */
export const Navigation: React.FC<React.PropsWithChildren<NavigtaionProps>> = ({
  config,
  userProfile = {},
  t,
  mode = 'complete',
  simpleNavigationTitle,
  handleSignOut,
}: NavigtaionProps) => {
  const [profileMenu, setProfileMenu] = React.useState(null);

  const {
    tenantName,
    tenantDisplay: {
      logos: { logo },
    },
    featureFlags,
    menuItems = [],
  } = config;
  const theme = useTheme();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { userFeatureFlags, roleSlug } = userProfile;
  const profileIsLoading = Object.keys(userProfile || {}).length === 0;

  const ref = useRef(null);

  // calculate responsive properties
  const { smUp, maxFullNavButtons, maxIconNavButtons } = useResponsiveProperties();

  // create the icon nav buttons which have custom behavior and are not vanilla app links
  const iconNavButtonLinks = createIconNavButtonLinks(t);

  // filter both sets of nav buttons to only display things with feature flags set to true and
  const allowedFullNavButtons = fullNavButtons.filter((button) => {
    const { requiredFeatureFlag, requiredRoles } = fullNavButtonLinks?.[button];

    return checkIfNavButtonIsAllowed({
      roleSlug,
      requiredRoles,
      featureFlags,
      requiredFeatureFlag,
    });
  });
  const allowedIconNavButtons = iconNavButtons.filter((button) => {
    const { requiredFeatureFlag, requiredUserFeatureFlag } = iconNavButtonLinks?.[button];

    return checkIfNavButtonIsAllowed({
      featureFlags,
      requiredFeatureFlag,
      userFeatureFlags,
      requiredUserFeatureFlag,
    });
  });

  const allowedMenuOnlyNavButtons = menuOnlyNavButtons.filter((button) => {
    const { requiredRoles, requiredFeatureFlag } = menuOnlyNavButtonLinks?.[button];

    return checkIfNavButtonIsAllowed({
      roleSlug,
      requiredRoles,
      featureFlags,
      requiredFeatureFlag,
    });
  });

  const allowedTenantNavButtons = menuItems?.filter((item) => {
    const { roles: requiredRoles } = item;

    return checkIfNavButtonIsAllowed({
      roleSlug,
      requiredRoles,
      requiredFeatureFlag: undefined,
      featureFlags: undefined,
    });
  });

  const hamburgerMenuItems = [
    // eslint-disable-next-line functional/immutable-data
    ...allowedFullNavButtons.splice(maxFullNavButtons),
    // The menu only nav buttons should always be displayed in the hamburger menu
    ...allowedMenuOnlyNavButtons,
    // reverse the buttons because while we need them displayed left to right they should fall into the
    // hamburger menu from the start of the list
    // eslint-disable-next-line functional/immutable-data
    ...allowedIconNavButtons.reverse().splice(maxIconNavButtons),
  ];

  // reorder the mutated array to ensure the display order is correct
  const orderedAllowedIconNavButtons = allowedIconNavButtons.slice().reverse();

  const LogoComponent = (
    <Link to={mode === 'complete' ? `/app` : '/'} style={{ height: '100%' }}>
      <Logo
        padding={0}
        logoURL={logo}
        tenantName={tenantName}
        maxHeight={featureFlags?.[CU_FeatureFlags.LOGO_FULL_HEIGHT] === true ? '100%' : '32px'}
        maxWidth="100%"
        wrapperSx={{
          maxHeight: '100%',
          height: '100%',
          py: 2,
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
        }}
      />
    </Link>
  );

  const SimpleNavigationTitle = simpleNavigationTitle ? (
    <Typography variant="h6" component="h1" sx={{ textAlign: 'center' }}>
      {simpleNavigationTitle}
    </Typography>
  ) : null;

  const commonAccountMenuProps = {
    t,
    handleSignOut,
    open: Boolean(profileMenu),
    mainItems: hamburgerMenuItems.map((item) => {
      if (item in fullNavButtonLinks) return fullNavButtonLinks[item as FullNavButton];
      if (item in iconNavButtonLinks) return iconNavButtonLinks[item as IconNavButton];
      if (item in menuOnlyNavButtonLinks) return menuOnlyNavButtonLinks[item as MenuOnlyNavButton];
    }),
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      data-testid={`${mode}-navbar`}
      sx={{
        height: '100%',
        px: theme?.custom?.spacing?.['3xl'],
        display: 'flex',
        justifyContent: { xxs: 'space-between', lg: mode === 'complete' ? 'flex-start' : 'space-between' },
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '1280px',
        margin: '0 auto',
      }}
    >
      {mode === 'simple' && (
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" height="100%">
          <Box ref={ref} mr={smUp ? undefined : 4} height="100%">
            {LogoComponent}
          </Box>
          {SimpleNavigationTitle}
          {/* As the logo width varies, use a ref to get the width and then add a box of the same size on the right
          to keep the title centred on desktop */}
          {smUp && <Box width={ref?.current?.offsetWidth || 0} height={0} />}
        </Box>
      )}
      {mode === 'complete' ? (
        <>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyItems: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box height="100%">{LogoComponent}</Box>
            {allowedFullNavButtons.length > 0 && (
              <FullNavBarLink
                t={t}
                fullNavButtons={allowedFullNavButtons.map((button) => {
                  return fullNavButtonLinks[button];
                })}
              />
            )}
          </Box>

          {/* The right sided part of the navigation, contains icon buttons and profile avatar */}
          <Box sx={{ ml: { lg: 'auto' } }}>
            {profileIsLoading ? (
              <Skeleton animation="wave" variant="circular" />
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {orderedAllowedIconNavButtons?.map((button) => {
                  return <Box key={button}>{iconNavButtonLinks[button]?.standard}</Box>;
                })}

                <ProfileAvatarButton
                  profile={userProfile}
                  isLoading={profileIsLoading}
                  onClick={(event: React.SyntheticEvent) => {
                    setProfileMenu(event.currentTarget);
                  }}
                  t={t}
                />
              </Box>
            )}
            {Boolean(profileMenu) && (
              <>
                {isSmallDevice ? (
                  <MobileAccountMenu
                    setProfileMenu={setProfileMenu}
                    logoComponent={LogoComponent}
                    tenantNavButtons={allowedTenantNavButtons}
                    {...commonAccountMenuProps}
                  />
                ) : (
                  <AccountMenu
                    anchorEl={profileMenu}
                    onClose={() => {
                      setProfileMenu(null);
                    }}
                    tenantNavButtons={allowedTenantNavButtons}
                    {...commonAccountMenuProps}
                  />
                )}
              </>
            )}
          </Box>
        </>
      ) : null}
    </AppBar>
  );
};
