import { TaskSearchResultExternalSla, SlaCurrentStatusReadOnly } from '@askporter/client-grieg-lyric';

export type SlaStatusType = 'DueStatus' | 'RespondStatus' | 'ResolveStatus';
export type SlaFilter = 'RESOLUTION' | 'RESPONSE' | undefined;

type GetSlaStatus = ({ sla, slaFilter }: { sla: TaskSearchResultExternalSla; slaFilter: SlaFilter }) => {
  slaStatusType: SlaStatusType;
  currentStatus: SlaCurrentStatusReadOnly;
};

export const getSlaStatus: GetSlaStatus = ({ sla, slaFilter }) => {
  const { type = null, response = null, resolution = null, due = null } = sla;

  if (slaFilter === 'RESOLUTION' && resolution) {
    return { slaStatusType: 'ResolveStatus', currentStatus: resolution?.status };
  } else if (slaFilter === 'RESPONSE' && response) {
    return { slaStatusType: 'RespondStatus', currentStatus: response?.status };
  }

  if (type === 'USER' && due) {
    return {
      slaStatusType: 'DueStatus',
      currentStatus: due?.status,
    };
  } else if (
    resolution &&
    response &&
    [SlaCurrentStatusReadOnly.Running, SlaCurrentStatusReadOnly.Overdue, SlaCurrentStatusReadOnly.Paused].includes(
      response?.status as any,
    )
  ) {
    return { slaStatusType: 'RespondStatus', currentStatus: response?.status };
  } else if (
    resolution &&
    response &&
    [SlaCurrentStatusReadOnly.CompletedLate, SlaCurrentStatusReadOnly.CompletedOnTime].includes(response?.status as any)
  ) {
    return { slaStatusType: 'ResolveStatus', currentStatus: resolution?.status };
  }
  return null;
};
