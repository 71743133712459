import React from 'react';
import { Stack, SxProps, Theme, Typography, useTheme, OutlinedInput, OutlinedInputProps, Tooltip } from '@mui/material';
import { TooltipState } from '@askporter/utils';
import { Button } from '../..';

type InputProps = {
  value: string;
  disabled: boolean;
  onChange?: (val: string) => void;
} & Pick<OutlinedInputProps, 'onBlur' | 'endAdornment' | 'placeholder' | 'aria-label' | 'aria-describedby'>;

type ActionButtonProps = {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon: React.JSX.Element;
};

type MultiInputProps = {
  input1: InputProps;
  input2: InputProps;
  actionButton: ActionButtonProps;
  inputHint: string;
  inputHintId?: string;
  error?: boolean;
  isLoading?: boolean;
  tooltipState?: TooltipState;
};

/**
 * Renders 2 input fields and a action buttun
 */
export const MultiInput: React.FC<MultiInputProps> = ({
  input1,
  input2,
  actionButton,
  inputHint,
  inputHintId,
  error = false,
  isLoading = false,
  tooltipState = { visible: false, message: '' },
}) => {
  const theme = useTheme();
  const standardBorder = `1px solid ${theme.palette.grey[400]}`;

  const commonStyleProps: SxProps<Theme> = {
    margin: 0,
    height: '40px',
    border: standardBorder,
  };
  const commonInputStyleProps: SxProps<Theme> = {
    '& .MuiOutlinedInput-notchedOutline': {
      // Remove border between the middle input and the item next to it
      border: 0,
    },
  };
  const noRightBorderRadiusStyle: SxProps<Theme> = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  };
  const noLeftBorderRadiusStyle: SxProps<Theme> = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  };

  return (
    <Stack direction="column">
      <Stack direction="row">
        <OutlinedInput
          value={input1.value}
          placeholder={input1.placeholder}
          inputProps={{ 'aria-label': input1['aria-label'], 'aria-describedby': input1['aria-describedby'] }}
          onChange={(e) => input1.onChange && input1.onChange(e.target.value)}
          endAdornment={input1.endAdornment}
          disabled={input1.disabled || isLoading}
          error={!input1.disabled && error}
          onBlur={input1.onBlur}
          size="small"
          sx={{
            ...commonStyleProps,
            border: !input1.disabled && error ? `1px solid ${theme.palette.error.main}` : standardBorder,
            ...commonInputStyleProps,
            ...noRightBorderRadiusStyle,
          }}
        />
        <OutlinedInput
          value={input2.value}
          placeholder={input2.placeholder}
          inputProps={{ 'aria-label': input2['aria-label'], 'aria-describedby': input2['aria-describedby'] }}
          onChange={(e) => input2.onChange && input2.onChange(e.target.value)}
          endAdornment={input2.endAdornment}
          disabled={input2.disabled || isLoading}
          error={!input2.disabled && error}
          onBlur={input2.onBlur}
          size="small"
          sx={{
            ...commonStyleProps,
            border: !input2.disabled && error ? `1px solid ${theme.palette.error.main}` : standardBorder,
            ...commonInputStyleProps,
            ...noLeftBorderRadiusStyle,
            ...noRightBorderRadiusStyle,
            borderLeft: 0,
            borderRight: 0,
          }}
        />
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="top-end"
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          open={tooltipState.visible}
          title={tooltipState.message}
        >
          <Button
            variant="outlined"
            color="inherit"
            startIcon={actionButton.icon}
            disabled={isLoading}
            onClick={actionButton.onClick}
            sx={{
              ...commonStyleProps,
              '&.Mui-disabled': { border: standardBorder },
              ...noLeftBorderRadiusStyle,
            }}
          >
            {actionButton.label}
          </Button>
        </Tooltip>
      </Stack>
      <Typography
        sx={{
          mt: theme.custom.spacing.sm,
          color: error && theme.palette.error.main,
        }}
        variant="body2"
        id={inputHintId}
      >
        {inputHint}
      </Typography>
    </Stack>
  );
};
