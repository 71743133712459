import React from 'react';
import { TitleField, Typography } from '../../../../../../..';

interface DetailsPageHeaderTitleProps {
  title: string;
  isTitleEditable: boolean;
  onChange: (title: string) => void;
  isSmallDevice: boolean;
}

export const DetailsPageHeaderTitle: React.FC<DetailsPageHeaderTitleProps> = ({
  title,
  isTitleEditable,
  onChange,
  isSmallDevice,
}) => {
  if (!isTitleEditable)
    return (
      <Typography variant="h6" noWrap={true} width="100%" sx={{ cursor: 'default', fontSize: '24px' }}>
        {title}
      </Typography>
    );

  return (
    <TitleField
      data-testid="title-input"
      variant="filled"
      fullWidth={true}
      defaultValue={title}
      onBlur={(event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value: newTitle } = event.currentTarget;
        if (newTitle !== title) onChange(newTitle);
      }}
      aria-label={title}
      sx={{
        my: 0,
        '.MuiInputBase-input': {
          '&:focus': {
            p: `${isSmallDevice ? '10px 4px' : '0 4px'}`,
          },
          p: '0',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '24px',
        },
      }}
    />
  );
};
