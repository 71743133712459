import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { SearchTaskResultExternal, TaskTypeReadExternal } from '@askporter/client-grieg-lyric';
import { OptionsDropdown, LinkedListItem, Icon, IconSize } from '@askporter/component-library';
import { LinkedTaskMenuOptions, OptionType } from '@askporter/utils';

interface LinkedTasksSectionProps {
  tasks?: SearchTaskResultExternal['results'];
  generateMenuOptions: (
    taskType: TaskTypeReadExternal,
    taskUid: string,
    taskURN: string,
  ) => OptionType<LinkedTaskMenuOptions, SxProps>[];
  menuTitle: string;
  isSmallDevice: boolean;
}

/**
 * Renders a LinkedTasksSection component
 * @param tasks - tasks to list
 * @param generateMenuOptions - generates menu options
 * @param menuTitle - title for the menu, only seen on mobile
 * @param isSmallDevice - small device or not
 */
export const LinkedTasksSection: React.FC<React.PropsWithChildren<LinkedTasksSectionProps>> = ({
  tasks,
  generateMenuOptions,
  menuTitle,
  isSmallDevice,
}: LinkedTasksSectionProps) => {
  return (
    <>
      {tasks?.map((task, idx) => {
        const { uid, urn, title, taskRef, taskType } = task || {};

        const options: OptionType<LinkedTaskMenuOptions, SxProps>[] = generateMenuOptions(taskType, uid, urn);

        const icon = task?.taskType?.icon;
        return (
          <LinkedListItem
            key={`${uid}-${idx}`}
            title={title}
            titleVariant="body1"
            subTitle={taskRef}
            icon={
              <Icon
                fullPath={icon?.iconPath}
                size={IconSize.MD}
                colorMask={{ size: '48px', color: icon?.iconColourMask }}
              />
            }
            isSmallDevice={isSmallDevice}
          >
            {
              <OptionsDropdown
                isSmallDevice={isSmallDevice}
                optionsParams={{
                  title: menuTitle,
                  options,
                }}
                elementBelowTitle={
                  <Box pl={8} display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center" mb={2}>
                    <Icon
                      fullPath={icon?.iconPath}
                      size={IconSize.SM}
                      colorMask={{ size: '32px', color: icon?.iconColourMask }}
                      folder="system/01-tasks"
                    />
                    <Typography ml={2} variant="subtitle1">
                      {title}
                    </Typography>
                    <Typography ml={2} variant="subtitle1">
                      {title && taskRef ? '•' : ''}
                    </Typography>
                    <Typography ml={2} variant="subtitle2" color="textSecondary">
                      {taskRef}
                    </Typography>
                  </Box>
                }
              />
            }
          </LinkedListItem>
        );
      })}
    </>
  );
};
