import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { TaskFullExternalLink } from '@askporter/client-grieg-lyric';
import { Breadcrumb } from '../../../../..';
import { HeaderExternalLinkButton } from '../../../../HeaderExternalLinkButton';
import { ReturnButton } from '../../../../ReturnButton';
import { DetailsPageHeaderBreadCrumbs, ResourceName } from './component/DetailsPageHeaderBreadCrumbs';
import { DetailsPageHeaderTitle } from './component/DetailsPageHeaderTitle';

type BreadcrumbsUnion =
  | {
      resourceName: ResourceName;
      resourceUid: string;
      copyBreadcrumbText: string;
      breadcrumbsArray: { key: string; text: string; href?: string }[];
    }
  | {
      resourceName?: undefined;
      resourceUid?: undefined;
      copyBreadcrumbText?: undefined;
      breadcrumbsArray?: undefined;
    };

type DetailsPageHeaderProps = {
  t: (key: string, options?: Record<string, string | number>) => string;
  isSmallDevice: boolean;
  defaultReturnPath?: string;
  defaultReturnPageTitle?: string;
  externalLink?: TaskFullExternalLink;
  progressIndicator?: React.JSX.Element;
  title: string;
  subtitle: React.ReactNode;
  icon: React.ReactNode;
  onChange?: (title?: string) => void;
  isTitleEditable?: boolean | undefined;
  chips?: React.ReactNode;
} & BreadcrumbsUnion;

export const DetailsPageHeader: React.FC<DetailsPageHeaderProps> = ({
  t,
  isSmallDevice,
  defaultReturnPath,
  defaultReturnPageTitle,
  externalLink,
  progressIndicator,
  isTitleEditable,
  title,
  onChange,
  subtitle,
  icon,
  chips,
  resourceName,
  copyBreadcrumbText,
  breadcrumbsArray,
  resourceUid,
}) => {
  const {
    custom: { spacing },
  } = useTheme();
  // Breadcrumbs exist if the breadcrumb props are passed
  const hasBreadcrumbs = Boolean(resourceName && resourceUid && copyBreadcrumbText && breadcrumbsArray);

  const resourceBreadcrumbs =
    hasBreadcrumbs && breadcrumbsArray?.map(({ key, text, href }) => <Breadcrumb text={text} key={key} href={href} />);

  return (
    <Box>
      {hasBreadcrumbs && (
        <DetailsPageHeaderBreadCrumbs
          t={t}
          resourceName={resourceName}
          resourceUid={resourceUid}
          copyBreadcrumbText={copyBreadcrumbText}
          resourceBreadcrumbs={resourceBreadcrumbs}
        />
      )}
      {!hasBreadcrumbs && !isSmallDevice ? (
        <ReturnButton
          t={t}
          isReturn
          defaultReturnPath={defaultReturnPath}
          defaultReturnPageTitle={t(defaultReturnPageTitle)}
        />
      ) : null}
      <Box width="100%" display="flex" flexDirection="row" justifyContent={'flex-start'} data-testid="title-header">
        <Box display="flex" flexDirection="row" width={'100%'}>
          {icon && (
            <Box mr={4} data-testid="icon">
              {icon}
            </Box>
          )}
          <Box sx={{ width: '100%' }}>
            {/* Title - text with tooltip or text field */}
            <DetailsPageHeaderTitle
              title={title}
              isTitleEditable={isTitleEditable}
              onChange={onChange}
              isSmallDevice={isSmallDevice}
            />
            {/* Subtitle */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography component="div" color="textSecondary">
                {subtitle}
              </Typography>
              <Box sx={{ pl: spacing.md }}>{chips}</Box>
            </Box>
          </Box>
          {externalLink && !isSmallDevice ? <HeaderExternalLinkButton externalLink={externalLink} /> : null}
        </Box>
      </Box>
      {externalLink && isSmallDevice ? (
        <Box width="100%" mt={4}>
          <HeaderExternalLinkButton externalLink={externalLink} />
        </Box>
      ) : null}
      {progressIndicator}
    </Box>
  );
};
