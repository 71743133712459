import React from 'react';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { ReturnButton } from '../../';

export interface BasicPageTemplateProps {
  pageTitle?: string | JSX.Element;
  isSmallDevice: boolean;
  children?: JSX.Element | JSX.Element[];
  testId?: string;
  returnButtonText?: string;
  isReturn?: boolean;
  showReturnButton?: 'always' | 'small-device' | 'never';
  smallDeviceTopRight?: JSX.Element | JSX.Element[];
  containerSx?: BoxProps['sx'];
  submitButton?: React.ReactNode;
  actionButtons?: React.ReactNode[];
  t: (key: string, options?: Record<string, string | number>) => string;
  optionsDropdown?: JSX.Element;
}

/**
 * Renders a minimal generic page template that helps drive consistency in terms of spacing, title size & return buttons
 *
 * @param pageTitle - (optional) main title for the page
 * @param isSmallDevice - small viewport or not
 * @param children - (optional) page contents
 * @param testId - the value for the 'data-testid' attribute
 * @param returnButtonText - (optional) useful when we want to show text other than 'Back'
 * @param isReturn - return button is return property
 * @param showReturnButton - (optional) 'always' | 'small-device' | 'never'
 * @param smallDeviceTopRight - (optional) on  for rendering an element opposite the mobile back button (small devices ONLY)
 * @param submitButton - (optional) Usually the RJSF submit button to show on the top corner on all viewports
 * @param t - translation function
 */
export const BasicPageTemplate: React.FC<React.PropsWithChildren<BasicPageTemplateProps>> = ({
  pageTitle,
  isSmallDevice,
  children,
  testId,
  returnButtonText,
  isReturn,
  showReturnButton = 'small-device',
  smallDeviceTopRight,
  containerSx,
  submitButton,
  t,
  optionsDropdown,
  actionButtons,
}: BasicPageTemplateProps) => {
  const {
    custom: { spacing },
  } = useTheme();
  const showSmallDeviceTopRight = !!smallDeviceTopRight && isSmallDevice;
  const Title: JSX.Element =
    typeof pageTitle === 'string' ? (
      <Typography variant="h5" component="h2">
        {pageTitle}
      </Typography>
    ) : (
      pageTitle
    );

  const Return = returnButtonText ? (
    <ReturnButton t={t} isReturn={isReturn} sx={{ mb: showSmallDeviceTopRight ? 0 : undefined }}>
      {returnButtonText}
    </ReturnButton>
  ) : (
    <ReturnButton t={t} isReturn={isReturn} sx={{ mb: 0 }} />
  );

  return (
    <Box data-testid={`basic-page-template${testId ? `-${testId}` : ''}`} sx={containerSx} height="100%">
      <Box display="flex" flexDirection="column" height="100%">
        {(isSmallDevice || showReturnButton === 'always') && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={isSmallDevice ? 4 : 6}
            height="24px"
          >
            {showReturnButton === 'always' && Return}
            {showReturnButton === 'small-device' && isSmallDevice && Return}
            {showSmallDeviceTopRight && smallDeviceTopRight}
            {submitButton && !(!isSmallDevice && pageTitle) ? submitButton : <></>}
            {optionsDropdown && !(!isSmallDevice && pageTitle) ? optionsDropdown : <></>}
          </Box>
        )}

        {!isSmallDevice && pageTitle ? (
          <Box display="flex" justifyContent={'space-between'} width="100%" alignItems="center">
            {Title}
            {submitButton ? submitButton : <Box height={36.5} />}
            {optionsDropdown ? optionsDropdown : null}
            {actionButtons && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.lg }}>
                {actionButtons.map((actionButton, index) => (
                  <Box key={index}>{actionButton}</Box>
                ))}
              </Box>
            )}
          </Box>
        ) : (
          <></>
        )}
        {isSmallDevice && pageTitle && <Box mb={4}>{Title}</Box>}
        {isSmallDevice && actionButtons && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.lg }}>
            {actionButtons.map((actionButton, index) => (
              <Box key={index}>{actionButton}</Box>
            ))}
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
};
